import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { setHeader } from '../../actions/header'
import { setHasChosenSubscriptionPlan } from '../../actions/user'
import LoadingIndicator from '../../components/GUI/LoadingIndicator'
import lang from '../../utils/lang'
import { postUpgradeAccountInfo } from '../../services/accounts.service'
import { hasErrorCode, showFailedToContactServerError } from '../../utils/errors'
import { useOnFirstMount } from '../../utils/hooks'
import { firmCommitmentConfirmation, pricingPlanDescription } from './PricingPlans'
import { CountryDropdown } from 'react-country-region-selector'
import { notificationService } from '../../services/notification.service'

const initialValues = {
    name: '',
    addressStreet: '',
    addressZipcode: '',
    addressCity: '',
    addressState: '',
    addressCountryCode: '',
    vat: '',
    billingEmail: '',
    subscriptionPlan: '',
    agreedToTerms: false
}

const validationSchema = yup.object().shape({
    name: yup.string().required(),
    addressStreet: yup.string().required(),
    addressZipcode: yup.string().required(),
    addressCity: yup.string().required(),
    addressState: yup.string(),
    addressCountryCode: yup.string().required(),
    vat: yup.string().notRequired(),
    billingEmail: yup.string().email().required(),
    subscriptionPlan: yup.string().required(),
    agreedToTerms: yup.boolean()
})

const Upgrade = (props) => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const history = useHistory()

    useOnFirstMount(() => history.push('/account/change-plan'), user.company.hasChosenSubscriptionPlan)

    useEffect(() => {
        dispatch(setHeader({
            leftItems: [{
                to: '/account',
                icon: 'icon-arrow-light-left',
                string_key: 'account',
                className: 'soft-button'
            }]
        }))
    }, [dispatch, history])

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values, { setStatus }) => {
            try {
                await postUpgradeAccountInfo(values)
                dispatch(setHasChosenSubscriptionPlan())
                setStatus('submitted')
            } catch (e) {
                const err = e.response.data
                if (hasErrorCode(err, "INVALID_DATA")) {
                    formik.setFieldError(err.errors[0].data.field, lang.d(err.errors[0].data.key))
                    notificationService.error(lang.d('error'), lang.d(err.errors[0].data.key))
                } else {
                    showFailedToContactServerError(err)
                }
                setStatus(null)
            }
        }
    })

    const renderFormik = () => {
        const errorClassName = fieldName => formik.errors[fieldName] ? 'error' : null

        return (
            <form className={`two-column ${formik.isSubmitting && 'submitting'} ${formik.status}`} onSubmit={formik.handleSubmit}>
                <div className="submitted-notice">
                    <div className="display-title">{ lang.d('upgrade-requested-title') }</div>
                    <div className="display-text">{ lang.d('upgrade-requested-description') }</div>
                </div>
                <div className="display-title">{ lang.d('upgrade-account') }</div>
                <div className="display-text">{ lang.d('upgrade-account-description') }</div>
                <div className="form-field">
                    <div className="description">
                        <label htmlFor="name">{ lang.d('business-name-and-legal-form') }</label>
                    </div>
                    <div className="field">
                        <input type="text" placeholder={lang.d('business-name-and-legal-form-placeholder')}
                            id="name" name="name" className={errorClassName('name')}
                            onChange={formik.handleChange} value={formik.values.name}/>
                    </div>
                </div>
                <div className="form-field">
                    <div className="description">
                        <label htmlFor="addressStreet">{ lang.d('legal-address') }</label>
                    </div>
                    <div className="field">
                        <input type="text" id="addressStreet" name="addressStreet" onChange={formik.handleChange} value={formik.values.addressStreet} placeholder={lang.d('street-address')} className={errorClassName('addressStreet')} />
                        <input type="text" name="addressZipcode" onChange={formik.handleChange} value={formik.values.addressZipcode} placeholder={lang.d('postal-zipcode')} className={errorClassName('addressZipcode')} />
                        <input type="text" name="addressCity" onChange={formik.handleChange} value={formik.values.addressCity} placeholder={lang.d('city')} className={errorClassName('addressCity')} />
                        <input type="text" name="addressState" onChange={formik.handleChange} value={formik.values.addressState} placeholder={lang.d('state-province')} className={errorClassName('addressState')} />
                        <CountryDropdown
                            name="addressCountryCode"
                            valueType="short"
                            defaultOptionLabel={lang.d('country')}
                            onChange={(val, e) => formik.handleChange(e)}
                            value={formik.values.addressCountryCode}
                            className={errorClassName('addressCountryCode')}
                        />
                    </div>
                </div>
                <div className="form-field">
                    <div className="description">
                        <label htmlFor="vat">{ lang.d('vat-number') }</label>
                        <div className="hint">
                            <strong>{ lang.d('optional') }.</strong> { lang.d('vat-number-hint') }
                        </div>
                    </div>
                    <div className="field">
                        <input type="text" id="vat" name="vat" onChange={formik.handleChange} value={formik.values.vat} className={errorClassName('vat')} />
                    </div>
                </div>
                <div className="form-field">
                    <div className="description">
                        <label htmlFor="billingEmail">{ lang.d('contact-email') }</label>
                        <div className="hint">{ lang.d('contact-email-hint') }</div>
                    </div>
                    <div className="field">
                        <input type="text" id="billingEmail" name="billingEmail" onChange={formik.handleChange} value={formik.values.billingEmail} className={errorClassName('billingEmail')} />
                    </div>
                </div>
                <div className="form-field">
                    <div className="description">
                        <label htmlFor="subscriptionPlan">{ lang.d('pricing-plan') }</label>
                        <a href="https://www.seats.io/pricing" className="hint external-link" target="_blank" rel="noopener noreferrer">{ lang.d('pricing-plan-link') }</a>
                    </div>
                    <div className="field">
                        <select id="subscriptionPlan" name="subscriptionPlan" onChange={formik.handleChange} value={formik.values.subscriptionPlan} className={errorClassName('subscriptionPlan')}>
                            <option value="" disabled>{ lang.d('please_select') }</option>
                            <option value="YEARLY_SILVER">Silver (yearly)</option>
                            <option value="MONTHLY_GOLD">Gold (monthly)</option>
                            <option value="YEARLY_GOLD">Gold (yearly)</option>
                            <option value="MONTHLY_DIAMOND">Diamond (monthly)</option>
                            <option value="YEARLY_DIAMOND">Diamond (yearly)</option>
                            <option value="MONTHLY_PLATINUM">Platinum (monthly)</option>
                            <option value="YEARLY_PLATINUM">Platinum (yearly)</option>
                            <option value="MONTHLY_MITHRIL">Mithril (monthly)</option>
                            <option value="YEARLY_MITHRIL">Mithril (yearly)</option>
                        </select>
                        {formik.values.subscriptionPlan &&
                            <div className="hint dark">
                                {pricingPlanDescription(formik.values.subscriptionPlan)}
                            </div>
                        }
                    </div>
                </div>
                <div className="form-field single-field">
                    <input type="checkbox" id="agreedToTerms" name="agreedToTerms" onChange={formik.handleChange} value={formik.values.agreedToTerms} className={errorClassName('agreedToTerms')} />
                    <span className="checkbox-label">
                        <label htmlFor="agreedToTerms">{ lang.d('read-and-agree-seatsio-terms-of-use') }</label>
                        <a href="https://www.seats.io/legal/terms" target="_blank" className="external-link" rel="noopener noreferrer">{ lang.d('read-terms-of-use') }</a>
                    </span>
                </div>
                <div className="form-field single-field">
                    { lang.d('i-legally-commit') }
                </div>
                <div className="form-actions">
                    { formik.isSubmitting
                        ? <LoadingIndicator />
                        : <input type="submit" value="Upgrade" disabled={!formik.values.agreedToTerms} />
                    }
                </div>
                <div className="form-field single-field center firm-commitment-notice">
                    {formik.values.subscriptionPlan &&
                        <span>
                            {firmCommitmentConfirmation(formik.values.subscriptionPlan)}
                        </span>
                    }
                </div>
            </form>
        )
    }

    return <div className="Upgrade">
        <div className="sectioned-page">
            <div className="sectioned-page-container">
                <div className="section">
                    { renderFormik() }
                </div>
            </div>
        </div>
    </div>
}

export default Upgrade
