import React from 'react'
import PropTypes from 'prop-types'
import { setHeader } from '../../actions/header'
import { connect } from 'react-redux'
import { getUpgradeSubscriptionPlan } from '../../services/accounts.service'
import styles from '../../styles/UpgradeSummary.less'
import lang from '../../utils/lang'
import Button from '../../components/GUI/Button'
import FormButton from '../../components/GUI/FormButton'

class UpgradeSummary extends React.Component {

    constructor (props) {
        super(props)
        this.state = {
            requestedSubscriptionPlan: null
        }
    }

    async componentDidMount () {
        this.props.setHeader({})
        const requestedSubscriptionPlan = await getUpgradeSubscriptionPlan()
        this.setState({requestedSubscriptionPlan})
    }

    renderSummary () {
        return <div className={styles.container}>
            <div className={styles.iconContainer}>
                <div className={['icon', 'icon-check', styles.icon].join(' ')} />
            </div>
            <div className={styles.heading}>{lang.d('thankYouForYourOrder')}</div>
            {this.state && this.state.requestedSubscriptionPlan && <div className={styles.details}>
                {lang.d('upgradeThankYou', { selectedPlan: this.state.requestedSubscriptionPlan.caption })}
            </div>}
            <FormButton onClick={() => this.props.history.push('/')} className={styles.actionButton}>{lang.d('returnToCharts')}</FormButton>
        </div>
    }

    render () {
        return <div className={styles.PaymentSummary}>
            <div className="sectioned-page">
                <div className="sectioned-page-container">
                    <div className="section">
                        { this.renderSummary() }
                    </div>
                </div>
            </div>
        </div>
    }
}

UpgradeSummary.propTypes = {
    setHeader: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
}

const mapDispatchToProps = dispatch => ({
    setHeader: payload => dispatch(setHeader(payload))
})

export default connect(null, mapDispatchToProps)(UpgradeSummary)
