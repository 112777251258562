import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { setHeader } from '../actions/header'
import { prepareNewPaymentMethod } from '../services/companies.service'
import { loadStripe } from '@stripe/stripe-js'
import { Environment } from '../environment'
import {
    AddressElement,
    Elements,
    PaymentElement,
    useElements,
    useStripe
} from '@stripe/react-stripe-js'
import lang from "../utils/lang";
import {notificationService} from "../services/notification.service";
import FormButton from "../components/GUI/FormButton";
import {Link} from 'react-router-dom'

const stripePromise = loadStripe(Environment.stripePublishableKey, {locale: 'en'})

function SetupForm() {
    const stripe = useStripe()
    const elements = useElements()

    const handleSubmit = async (event) => {
        event.preventDefault()

        if (!stripe || !elements) {
            return null
        }

        const confirmationOptions = {
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/redirect-to?target=/invoices&messageKey=payment_method_updated`
            },
            redirect: 'always'
        }
        const {error} = await stripe.confirmSetup(confirmationOptions)

        if (error && error.type !== 'validation_error') {
            notificationService.error(lang.d('error'), error.message)
        } else {
            // Stripe redirects
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement options={{ layout: 'accordion' }} />
            <div className="actions">
                <Link className="action" to="/invoices">{lang.d('cancel')}</Link>
                <FormButton className="action" disabled={!stripe} onClick={handleSubmit.bind(this)}>{lang.d('update_payment_method')}</FormButton>
            </div>
        </form>
    )
}

class UpdatePaymentMethod extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            stripeOptions: null,
            error: null
        }
    }

    async componentDidMount() {
        this.props.setHeader({
            leftItems: [{
                to: '/invoices',
                icon: 'icon-arrow-light-left',
                string_key: 'invoices',
                className: 'soft-button'
            }]
        })
        const companyId = this.props.user.companyId
        try {
            const data = await prepareNewPaymentMethod(companyId)
            this.setState({ stripeOptions: {
                clientSecret: data.sessionKey
            }})
        } catch (e) {
            if (e.response && (e.response.status >= 400 && e.response.status < 500)) {
                this.setState({error: lang.d('unable_to_add_payment_method')})
            } else {
                this.setState({error: `${lang.d('unable_to_add_payment_method')} ${lang.d('please_try_again_later')}`})
            }
        }
    }

    renderPaymentMethodDetails () {
        return <Elements stripe={stripePromise} options={this.state.stripeOptions}>
            <SetupForm />
        </Elements>
    }

    render () {
        if (Environment.allowDirectStripeIntegration) {
            return <div className="UpdatePaymentMethod sectioned-page">
                <div className="sectioned-page-container with-expanded-header">
                    <div className="section">
                        <div className="page-title">{lang.d('update_payment_method')}</div>
                        <div className="page-description">
                            {this.state.error && <div>{this.state.error}</div>}
                            {!this.state.error && <div>{lang.d('paymentDetailsDescription')}</div>}
                        </div>
                    </div>
                    {this.state.stripeOptions && <div className="section">
                        {this.state.stripeOptions && this.renderPaymentMethodDetails()}
                    </div>}
                    {(!this.state.error && !this.state.stripeOptions) && <div className="section">
                        <div className="loading-placeholder">
                            <div className="fake-item double"></div>
                            <div className="fake-item"></div>
                            <div className="fake-item"></div>
                        </div>
                    </div>}
                </div>
            </div>
        } else {
            return <div className="AddPaymentMethod sectioned-page">
                <div className="sectioned-page-container with-expanded-header">
                    <div className="section">
                        <div className="page-title">{lang.d('add_a_payment_method')}</div>
                        <div className="page-description">
                            {lang.d('unable_to_add_payment_method')}
                        </div>
                    </div>
                </div>
            </div>
        }
    }
}

UpdatePaymentMethod.propTypes = {
    user: PropTypes.object,
    setHeader: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    user: state.user
})

const mapDispatchToProps = dispatch => ({
    setHeader: payload => dispatch(setHeader(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePaymentMethod)
